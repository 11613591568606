import { Masks } from 'utils';
import { URLSearchParams as URLSearchParamsType } from 'url';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import {
  FOLLOWED_CURRENCY_RATE_TYPE,
  ICurrency,
  Nullable,
  IEntityAccountDetailsType,
  IEntityPriorityAccountDetails,
  IOneOfEntityAccountDetails,
} from 'types';
import { LOOKUP } from 'variables';

export const asyncForEach = async (array: any[], callback: Function) => {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};

export const parseIntoCurrencyString = (
  value: number | undefined,
  precision = 2
) => {
  if (typeof value === 'undefined' || typeof value !== 'number') {
    return '';
  }

  const valueWithPrecisionApplied = roundToPrecision(value, precision);

  return Masks.stringToCurrencyNumberString(
    valueWithPrecisionApplied.toString(),
    precision
  );
};

export const parseIntoCurrencyStringWithSymbol = (
  value: Nullable<number>,
  currencySymbol: Nullable<string> = '',
  precision = 2
) => {
  if (typeof value === 'undefined' || typeof value !== 'number') {
    return '';
  }

  const isNegative = value < 0;

  return `${isNegative ? '-' : ''}${currencySymbol}${parseIntoCurrencyString(
    value,
    precision
  )}`;
};

export const parseIntoShortNumberString = (
  value?: number,
  precision?: number
) => {
  if (typeof value === 'undefined' || typeof value !== 'number') {
    return '';
  }

  const lookupItem = LOOKUP.find((item) => Math.abs(value) >= item.value);

  if (!lookupItem) {
    return '0';
  }

  const roundedValue = roundToPrecision(
    value / lookupItem.value,
    precision ?? lookupItem.digits
  );

  return roundedValue + lookupItem.symbol;
};

export const parseIntoShortNumberWithSymbol = (
  value: Nullable<number>,
  currencySymbol: Nullable<string> = '',
  precision = 2
) => {
  if (typeof value === 'undefined' || typeof value !== 'number') {
    return '';
  }

  const isNegative = value < 0;
  const positiveValue = isNegative ? value * -1 : value;

  return `${isNegative ? '-' : ''}${currencySymbol}${parseIntoShortNumberString(
    positiveValue,
    precision
  )}`;
};

export const parseRate = (rate: Nullable<number>) =>
  rate ? roundToPrecision(rate, rate < 1 ? 4 : 5) : rate;

export const parseRateWithPrecision = (rate: Nullable<number>) =>
  rate ? rate.toPrecision(rate < 1 ? 4 : 5) : 'N/A';

export const getCountryCodeByCurrency = (
  currencyCode: Nullable<ICurrency['code']>,
  currencies: Nullable<ICurrency[]>
): ICurrency['countryCode'] => {
  if (!currencies || !currencyCode) {
    return '';
  }

  const currency = currencies.find((item) => item.code === currencyCode);

  if (currency) {
    return currency.countryCode;
  }

  return '';
};

export const roundToPrecision = (amount: number, precision = 2) => {
  if (!amount) return amount;

  const usePrecision = Math.pow(10, precision);

  const roundedAmount = Math.round(amount * usePrecision) / usePrecision;

  return roundedAmount;
};

export const camelCaseToSentenceCase = (
  camelCaseText: string,
  upperCaseFirstLetter: boolean = true
) => {
  const sentenceCaseText = camelCaseText.replace(/([A-Z])/g, ' $1');

  if (upperCaseFirstLetter) {
    return sentenceCaseText.charAt(0).toUpperCase() + sentenceCaseText.slice(1);
  }

  return sentenceCaseText;
};

export const isProduction = () =>
  process.env.REACT_APP_ENVIRONMENT_NAME === 'PROD' ||
  process.env.REACT_APP_ENVIRONMENT_NAME === 'PROD_STAGING';

export const isEmulated = () =>
  process.env.REACT_APP_ENVIRONMENT_NAME === 'EMULATOR';

export const isLocal = () => process.env.NODE_ENV === 'development';

export const goToProductionLanding = () =>
  window.location.replace('https://www.hedgeflows.com/');

export const getSubstractedEmail = (str: any) =>
  str &&
  str.substr(0, 2) +
    '***@***' +
    str.substr(str.lastIndexOf('.'), str.length - str.lastIndexOf('.'));

export const getGoToStepQuery = (
  url: string,
  URLQuery: URLSearchParamsType,
  stepNumber: number
) => {
  URLQuery.set('step', stepNumber.toString());

  return `${url}?${URLQuery.toString()}`;
};

export const getAccountDetailsForCopy = (
  valuesMappings: string[][],
  title = '',
  lineBreaksForMail = false
) => {
  if (lineBreaksForMail) {
    return valuesMappings.reduce(
      (total, [key, value]) => total + `${key}: ${value}%0D%0A`,
      `%0D%0A${title}%0D%0A%0D%0A`
    );
  }

  return valuesMappings.reduce(
    (total, [key, value]) =>
      total +
      `${key}: ${value}
`,
    `
${title}

`
  );
};

export const getSwiftAccountDetails = (
  entityAccountDetails: IOneOfEntityAccountDetails[]
) =>
  entityAccountDetails.find(
    (item: any): item is IEntityPriorityAccountDetails =>
      item.type === IEntityAccountDetailsType.priority
  );

export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

/**
 *By default the rate markup (on the mid rate) is 0.004 (0.4%).
 *The rate service only returns a pay rate (mid + 0.004).
 *This function reverses the default markup if the rateType = mid, and makes receive become mid - 0.004).
 *This function will not be required once the rates service can return pay, mid and receive rates.
 *Also the mid and receive rates are not 100% accurate for clients on non-default markups.
 */
export const getRateByRateType = (
  rate: UseCurrencyRateReturnValues['rate'],
  rateType: FOLLOWED_CURRENCY_RATE_TYPE,
  isInverted: boolean
) => {
  if (!rate) {
    return null;
  }

  let normalRate = rate;

  if (rateType === 'receive') {
    normalRate = rate * 1.008;
  }
  if (rateType === 'mid') {
    normalRate = rate * 1.004;
  }

  return isInverted ? 1 / normalRate : normalRate;
};

export const transformCurrencyToSelectOption = (currency: ICurrency) => ({
  value: currency,
  label: currency.code,
  icon: currency.countryCode,
});

export const firstCharacterToUppercase = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const openInNewTab = (url?: string) => {
  if (!url) {
    return;
  }

  window.open(url, '_blank', 'noopener,noreferrer');
};

export const getTimePeriodByMonths = (months: number) => {
  const yearValue = roundToPrecision(months / 12);
  const remainingMonths = months % 12;

  if (!remainingMonths && yearValue >= 1) {
    return `${yearValue} year${yearValue > 1 ? 's' : ''}`;
  }

  return `${months} month${months > 1 ? 's' : ''}`;
};

export const notUndefinedTypeGuard = <T = any>(
  element: T | undefined
): element is T => element !== undefined;

export const notNullTypeGuard = <T = any>(element: T | null): element is T =>
  element !== null;

/**
 * Determines whether an array contains duplicates.
 *
 * @return {boolean} Returns true if the array contains duplicates, false otherwise.
 */
export const hasDuplicatesInArray = (arr: unknown[]) =>
  new Set(arr).size !== arr.length;

/**
 * Generates a search query string based on the provided parameters. Ignores undefined values.
 *
 * @param params - An object containing key-value pairs representing the search parameters.
 * @returns The generated search query string including question mark.
 */
export const generateSearchQuery = (
  params: Record<string, string | number | boolean | undefined>
): string => {
  const paramsEntries = Object.entries(params);
  const paramsValues = Object.values(params).filter(notUndefinedTypeGuard);

  if (paramsValues.length === 0) {
    return '';
  }

  const query = new URLSearchParams();

  for (const [key, value] of paramsEntries) {
    if (value !== undefined && value !== '') {
      query.append(key, value.toString());
    }
  }

  return `?${query.toString()}`;
};

export const maskStringWithStars = (value?: string) => {
  if (typeof value === 'undefined' || typeof value !== 'string') {
    return '';
  }

  if (value.length <= 3) {
    return value;
  }
  return '*'.repeat(value.length - 3) + value.slice(-3);
};
