import { FC, useState, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form7';

import { useStoreState } from 'state';
import { useUpdateSettingsPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';
import {
  Row,
  Title,
  StaleSwitch,
  Subtitle,
  Col,
  StaleInfo,
  Paragraph,
} from 'components';
import Field from 'components/shared/Field/Field.styles';

import InvoicesApprovalFlowSettings from './components/InvoiceApprovalFlowSettings/InvoiceApprovalFlowSettings';
import { WhiteContentContainer } from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import Button from 'components/shared/Button/Button';
import { getFormDefaultValues, getValuesFromInputs } from './utils';
import PaymentRunApprovalSettings from './components/PaymentRunApprovalSettings/PaymentRunApprovalSettings';
import { StyledForm } from 'components/shared/Form/Form.styles';
import { setEntityApprovalSettings } from 'services/firebase/entities';
import { Notify } from 'utils';
import { IEntityApprovalSettings } from 'types';

type Select = { value: string; label: string };

export type TInputs = Omit<
  IEntityApprovalSettings,
  | 'defaultAmount' // un-used
  | 'autoApproveRecipientList'
  | 'approver1List'
  | 'approver2List'
  | 'exceptionalRecipientList'
  | 'exceptionalApproverList'
  | 'paymentRunsApprover1List'
  | 'paymentRunsApprover2List'
> & {
  autoApproveRecipientList: Select[];
  approver1List: Select[];
  approver2List: Select[];
  paymentRunsApprover1List: Select[];
  paymentRunsApprover2List: Select[];
  exceptionalRecipientList: Select[];
  exceptionalApproverList: Select[];
  twoApprovers?: boolean;
  paymentRunsTwoApprovers?: boolean;
};

const ApprovalSettings: FC = () => {
  const theme = useTheme();

  const {
    isAutomationPackageEnabled,
    userEntity,
    entityId,
    entityUsers,
  } = useStoreState(({ UserState }) => UserState);
  const hasUpdateSettingsPermission = useUpdateSettingsPermissionsCheck();
  const [
    isInvoicesApprovalFlowEnabled,
    setIsInvoicesApprovalFlowEnabled,
  ] = useState(false);
  const [
    isPaymentRunsApprovalFlowEnabled,
    setIsPaymentRunsApprovalFlowEnabled,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const defaultValues = useMemo(() => getFormDefaultValues({ userEntity }), [
    userEntity,
  ]);

  const form = useForm<TInputs>({
    defaultValues: defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid },
  } = form;

  useEffect(() => {
    if (!!userEntity.approvalSettings?.hasApprovalFlow) {
      setIsInvoicesApprovalFlowEnabled(true);
    }
    if (!!userEntity.approvalSettings?.paymentRunsApprovalsEnabled) {
      setIsPaymentRunsApprovalFlowEnabled(true);
    }
  }, [userEntity]);

  useEffect(() => {
    if (userEntity) {
      reset(defaultValues);
    }
  }, [userEntity, reset, defaultValues]);

  const onError = (error: any) => {
    console.log(error);
  };

  const onContinue = async (values: TInputs) => {
    try {
      if (entityId) {
        // These are outside of react-form and handled directly via react state.
        // Maybe because of the switch component not being form/control compatible.
        const hasApprovalFlow = isInvoicesApprovalFlowEnabled;
        const paymentRunsApprovalsEnabled = isPaymentRunsApprovalFlowEnabled;

        const {
          requiredNumberOfApprovals,
          requiredNumberOfApprovalsForPaymentRuns,
          autoApproveAmount,
          exceptionalAmount,
          autoApproveRecipientList,
          approver1List,
          approver2List,
          exceptionalRecipientList,
          exceptionalApproverList,
          paymentRunsApprover1List,
          paymentRunsApprover2List,
        } = getValuesFromInputs({
          values,
          hasApprovalFlow,
          paymentRunsApprovalsEnabled,
        });

        setIsLoading(true);

        const settingsToSave = {
          ...values,
          requiredNumberOfApprovals,
          requiredNumberOfApprovalsForPaymentRuns,
          autoApproveAmount,
          exceptionalAmount,
          autoApproveRecipientList,
          approver1List,
          approver2List,
          exceptionalRecipientList,
          exceptionalApproverList,
          hasApprovalFlow,
          paymentRunsApprovalsEnabled,
          paymentRunsApprover1List,
          paymentRunsApprover2List,
        };

        delete settingsToSave.twoApprovers;
        delete settingsToSave.paymentRunsTwoApprovers;

        await setEntityApprovalSettings({
          entityId,
          settings: settingsToSave,
        });
        setIsLoading(false);
        Notify.success('Approval settings saved');
      } else {
        console.log('entityId is null. not saving settings');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const disableSubmitButton =
    !userEntity ||
    !hasUpdateSettingsPermission ||
    !entityUsers ||
    isLoading ||
    !isValid;

  return (
    <>
      <WhiteContentContainer gap={theme.spacing.xxl}>
        <Col gap={theme.spacing.m}>
          <StyledForm
            alignItems="stretch"
            onSubmit={handleSubmit(onContinue, onError)}
            id="approvalsForm"
          >
            <Title mb variant="h4">
              Approval settings
            </Title>
            <Field fluid>
              <Row mr mb>
                <Subtitle mr variant="bold">
                  Invoice approvals
                </Subtitle>
                <StaleInfo mode="hover" strategy="fixed">
                  <Paragraph color="white">
                    Individual invoices must be approved before they can be
                    paid. Ideal for Payment runs with FX and adding approval
                    process for paying individual invoices.
                  </Paragraph>
                </StaleInfo>
              </Row>

              {isAutomationPackageEnabled && (
                <StaleSwitch
                  id="invoices-approval-flow"
                  isOn={isInvoicesApprovalFlowEnabled}
                  handleToggle={() =>
                    setIsInvoicesApprovalFlowEnabled((prev) => !prev)
                  }
                  disabled={!userEntity || !hasUpdateSettingsPermission}
                />
              )}
            </Field>

            {isInvoicesApprovalFlowEnabled && (
              <InvoicesApprovalFlowSettings form={form} />
            )}

            <Col mb>
              <Field fluid>
                <Row mr mb>
                  <Subtitle mr variant="bold">
                    Payment run approvals
                  </Subtitle>
                  <StaleInfo mode="hover" strategy="fixed">
                    <Paragraph color="white">
                      Payment run must be approved before they can be released
                      by HedgeFlows. It is ideal for larger payment runs and a
                      faster approval process.
                    </Paragraph>
                  </StaleInfo>
                </Row>
                {isAutomationPackageEnabled && (
                  <StaleSwitch
                    id="payment-runs-approval-flow"
                    isOn={isPaymentRunsApprovalFlowEnabled}
                    handleToggle={() =>
                      setIsPaymentRunsApprovalFlowEnabled((prev) => !prev)
                    }
                    disabled={!userEntity}
                  />
                )}
              </Field>

              {isPaymentRunsApprovalFlowEnabled && (
                <PaymentRunApprovalSettings form={form} />
              )}
            </Col>

            <Row>
              <Button
                mt
                disabled={disableSubmitButton}
                isLoading={isLoading}
                form="approvalsForm"
              >
                Submit
              </Button>
            </Row>
          </StyledForm>
        </Col>
      </WhiteContentContainer>
    </>
  );
};

export default ApprovalSettings;
