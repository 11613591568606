import { Column } from 'react-table';
import dayjs from 'dayjs';
import { Icon, Paragraph, Row } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { IWalletTransaction, TEventResultStatus } from './types';
import StatementTableActionsCell from './components/StatementTableActionsCell/StatementTableActionsCell';
import { ICurrency, Nullable } from 'types';
import { DATE_FORMAT_SHORT_MONTH_NAME } from 'variables';

export const generateStatementsTableColumns = ({
  currencyByCode,
}: {
  currencyByCode: (currency: string) => Nullable<ICurrency>;
}): Column<IWalletTransaction>[] => [
  {
    Header: 'Settled on',
    accessor: 'settledOn',
    Cell: ({ value }) => (
      <Paragraph>{value ? dayjs(value).format(DATE_FORMAT_SHORT_MONTH_NAME) : '-'}</Paragraph>
    ),
    width: 120,
  },
  {
    Header: 'Reference',
    accessor: 'reference',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 120,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 120,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 250,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Credit
      </Row>
    ),
    accessor: 'amountCredit',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value
            ? parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )
            : '-'}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Debit
      </Row>
    ),
    accessor: 'amountDebit',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value
            ? parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )
            : '-'}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Balance
      </Row>
    ),
    accessor: 'balance',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value
            ? parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )
            : '-'}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    id: 'auto-sync',
    Header: () => (
      <Row justifyContent="center" flex={1}>
        Auto-sync
      </Row>
    ),
    accessor: (row) => {
      const firstResource = row.writableResources?.[0];
      return firstResource?.status;
    },
    Cell: ({
      value,
    }: {
      value: TEventResultStatus | 'permission-error' | undefined;
    }) => (
      <Row justifyContent="center" flex={1}>
        {value !== 'success' && (
          <Icon icon="cross-ico" fill="red" width="16px" height="16px" />
        )}
        {value === 'success' && (
          <Icon
            icon="checkbox-checkmark"
            fill="green"
            width="16px"
            height="16px"
          />
        )}
      </Row>
    ),
    width: 120,
  },
  {
    id: 'dropdown',
    disableSortBy: true,
    Header: () => null,
    Cell: ({ row }: any) => (
      <StatementTableActionsCell walletTransaction={row.original} />
    ),
    width: 100,
  },
];
