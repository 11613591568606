import { FC, useMemo } from 'react';
import { Table, Row, Button, StaleInputSelect } from 'components';
import { generateStatementsTableColumns } from './tableColumnsGenerator';
import { useTheme } from 'styled-components';
import { IWalletTransaction } from './types';
import { getWalletTransactions } from 'services/transactions';
import { usePagination } from 'hooks';
import useUrlValues from 'hooks/useUrlValues';
import { useStoreState } from 'state';

interface IOwnProps {
  walletCurrencies: string[];
}

const StatementsTable: FC<IOwnProps> = ({ walletCurrencies }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { setUrlValue, currency } = useUrlValues('currency');

  const currenciesSelectData = useMemo(
    () =>
      walletCurrencies.map((currency) => ({
        id: currency,
        name: currency,
        value: currency,
        label: currency,
        icon: currencyByCode(currency)?.countryCode,
      })),
    [currencyByCode, walletCurrencies]
  );

  const extraQueryParams = useMemo(
    () => ({
      currency: currency ?? undefined,
    }),
    [currency]
  );

  const {
    data: walletTransactions,
    pagination,
    isLoading,
    loadMore,
    loadAll,
  } = usePagination<IWalletTransaction>({
    fetchCallback: getWalletTransactions,
    extraQueryParams,
    // Only fetch when currency is selected
    isReadyToFetch: !!extraQueryParams.currency,
  });

  const columns = useMemo(
    () => generateStatementsTableColumns({ currencyByCode }),
    [currencyByCode]
  );

  return (
    <>
      <Row mb mbValue={theme.spacing.m} justifyContent="space-between">
        <StaleInputSelect
          selected={currency}
          data={currenciesSelectData}
          onSelect={(item) => setUrlValue({ currency: item.value })}
          id="statements-currency"
          inputHeight="32px"
          style={{ minWidth: '132px' }}
        />

        <Row gap={theme.spacing.m}>
          <Button
            variant="link"
            disabled={
              isLoading ||
              !pagination.nextPageStartsAfter ||
              walletTransactions.length === pagination.totalCount
            }
            isLoading={isLoading}
            onClick={loadMore}
          >
            Load more
          </Button>

          <Button
            variant="link"
            disabled={
              isLoading || walletTransactions.length === pagination.totalCount
            }
            isLoading={isLoading}
            onClick={loadAll}
          >
            Load all (slow)
          </Button>
        </Row>
      </Row>

      <Table<IWalletTransaction>
        isVirtualized
        itemsCount={walletTransactions.length}
        columns={columns}
        data={walletTransactions}
      />
    </>
  );
};

export default StatementsTable;
