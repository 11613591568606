import { Loader, Paragraph } from 'components';
import { IHedgeRecommendationResult } from 'pages/HedgeRecommendations/useGetHedgeRecommendations';
import { FC, useEffect, useState } from 'react';
import { getForwardRateAndFees } from 'services/firebase';
import { parseRateWithPrecision } from 'utils';

interface IOwnProps {
  record: IHedgeRecommendationResult;
}

const IndicativeRateCell: FC<IOwnProps> = ({ record }) => {
  const [isLoading, setIsLoading] = useState(!record.rate);
  const [rate, setRate] = useState<number | 'N/A' | undefined>(record.rate);

  useEffect(() => {
    if (isLoading && !rate) {
      getForwardRateAndFees({
        buyCurrency: record.buyCurrency,
        sellCurrency: record.sellCurrency,
        dateString: record.expiryDate,
      })
        .then((res) => {
          setRate(res.data?.rate ?? 'N/A');
        })
        .catch(() => {
          setRate('N/A');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    record.buyCurrency,
    record.currency,
    record.expiryDate,
    record.sellCurrency,
    isLoading,
    rate,
  ]);

  if (isLoading) {
    return <Loader size="small" />;
  }

  return (
    <Paragraph>
      {rate === 'N/A' ? rate : parseRateWithPrecision(rate)}
    </Paragraph>
  );
};

export default IndicativeRateCell;
