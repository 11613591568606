import { ContextMenu, Row } from 'components';
import { useMemo, useState } from 'react';
import { IWalletTransaction } from '../../types';
import ConversionInner from 'components/shared/ConversionInner/ConversionInner';
import TransferInner from 'components/shared/TransferInner/TransferInner';

interface IOwnProps {
  walletTransaction: IWalletTransaction;
}

const StatementTableActionsCell = ({ walletTransaction }: IOwnProps) => {
  const [showConversionDetails, setShowConversionDetails] = useState(false);
  const [showTransferDetails, setShowTransferDetails] = useState(false);

  const contextMenuData = useMemo(() => {
    return [
      {
        id: 'details',
        title: 'Details',
        onClick: () => {
          if (walletTransaction.type === 'FX Exchange') {
            setShowConversionDetails(true);
          }

          if (
            walletTransaction.type === 'Transfer' ||
            walletTransaction.type === 'Transfer in' ||
            walletTransaction.type === 'Transfer out'
          ) {
            setShowTransferDetails(true);
          }
        },
      },
    ];
  }, [walletTransaction]);

  if (
    walletTransaction.type === 'Unknown' ||
    (walletTransaction.source !== 'transfer' &&
      walletTransaction.source !== 'conversion')
  ) {
    return null;
  }

  return (
    <>
      <Row flex={1} justifyContent="flex-end">
        <ContextMenu portal list={contextMenuData} />
      </Row>
      {showConversionDetails && (
        <ConversionInner
          conversionId={walletTransaction.sourceId}
          onClose={() => setShowConversionDetails(false)}
        />
      )}
      {showTransferDetails && (
        <TransferInner
          transferId={walletTransaction.sourceId}
          onClose={() => setShowTransferDetails(false)}
        />
      )}
    </>
  );
};

export default StatementTableActionsCell;
