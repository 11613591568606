import { FC, useEffect, useState } from 'react';
import StatementsTable from './components/StatementsTable/StatementsTable';
import { getWalletCurrencies } from 'services/transactions';
import { errorHandler } from 'utils/errors';
import { Loader } from 'components';
import useUrlValues from 'hooks/useUrlValues';

const Statements: FC = () => {
  const { currency,setUrlValue } = useUrlValues('currency');
  const [walletCurrencies, setWalletCurrencies] = useState<string[]>([]);

  useEffect(() => {
    getWalletCurrencies()
      .then((res) => setWalletCurrencies(res.data.data ?? []))
      .catch(errorHandler);
  }, []);

  useEffect(() => {
    if (walletCurrencies.length && !currency) {
      setUrlValue({ currency: walletCurrencies[0] });
    }
  }, [walletCurrencies, setUrlValue, currency]);

  if (!walletCurrencies.length) {
    return <Loader size="large" />;
  }

  return <StatementsTable walletCurrencies={walletCurrencies} />;
};

export default Statements;
