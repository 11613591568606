import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _orderBy from 'lodash.orderby';
import _get from 'lodash.get';
import _omit from 'lodash.omit';
import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from 'state';
import { Notify } from 'utils';
import { DB_DATE_FORMAT } from 'variables';
import { Firebase } from 'services';

import useIsAwaitingReviewOrRegistration from 'hooks/useIsAwaitingReviewOrRegistration';
import {
  RECIPIENT_STATUS,
  IContact,
  ICurrency,
  IRecipient,
  PAYMENT_TYPE,
  RECIPIENT_TYPE,
  TRANSFER_TYPE,
  Nullable,
  IRecipientMeta,
  TScamQuestions,
  PartialContact,
  isContact,
  IRecipientMetaField,
  IEntityDetails,
  IRecipientFieldInstructions,
  TPartialRecipientWithId,
} from 'types';
import {
  LoaderWrapper,
  TypesRow,
  FieldWrapper,
  FieldRow,
  FieldGroupWrapper,
  AddContactFormWrapper,
} from './AddContactForm.styles';
import {
  StaleCheckboxControlled,
  Loader,
  StaleInputRadioNew,
  Row,
  Popup,
  StaleInfo,
  BetaTag,
  Icon,
} from 'components';
import { Col } from '../Col/Col';
import Button from '../Button/Button';
import { Paragraph, Subtitle, Title } from '../Typography/Typography';
import { createCreatableSelectMenuOption } from '../CreatableSelectMenu/utils';
import { useTheme } from 'styled-components';
import { StyledForm } from '../Form/Form.styles';
import ScamAlert from './components/ScamAlert/ScamAlert';
import { isContactCountrySuspiciousCheck } from './utils';
import { errorHandler } from 'utils/errors';
import { createPortal } from 'react-dom';
import DirtyPopup from './components/DirtyPopup/DirtyPopup';
import AddContactFormField from './components/AddContactFormField/AddContactFormField';
import InvoicesWithAttachments from './components/InvoicesWithAttachments/InvoicesWithAttachments';
import { AddContactInputs, IImportableValuesFromOcr } from './types';
import ImportFromBank from './components/ImportFromBank/ImportFromBank';
import useImportDetails from './hooks/useImportDetails';
import FooterContent from './components/FooterContent/FooterContent';
import ValidationSection from './components/ValidationSection/ValidationSection';
import useIpIdValidation from './hooks/useIpIdValidation';
import { usePrevious } from 'hooks';

interface IOwnProps {
  metadata?: IRecipientMeta;
  initialCurrency: ICurrency;
  sellCurrency: ICurrency;
  initialTransferType: TRANSFER_TYPE;
  validateOnMount?: boolean;
  recipientForEdit?: Nullable<IContact | PartialContact>;
  onClose: () => void;
  onContinue?: () => void;
  setRecipient?: (state: IRecipient) => void;
  withDraftWarning?: boolean;
  withSaveAsDraft?: boolean;
  isForceCreate?: boolean;
  disableCurrency?: boolean;
}

const AddContactForm: FC<IOwnProps> = ({
  recipientForEdit,
  onClose,
  metadata: initialMetadata,
  initialCurrency,
  sellCurrency,
  initialTransferType,
  validateOnMount = false,
  setRecipient,
  onContinue,
  withDraftWarning = false,
  withSaveAsDraft = true,
  isForceCreate = false,
  disableCurrency = false,
}) => {
  const theme = useTheme();
  const {
    countries,
    countryByCode,
    getSwiftShared,
    getSwiftOurs,
  } = useStoreState(({ ReferenceDataState }) => ReferenceDataState);
  const { entityId, isEntityOnboarded } = useStoreState(
    ({ UserState }) => UserState
  );
  const { createRecipient, updateRecipient } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { getRecipientCreationMetadata } = useStoreActions(
    ({ RecipientsState }) => RecipientsState
  );
  const { getTransferPurposes } = useStoreActions(
    ({ TransfersState }) => TransfersState
  );
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, dirtyFields },
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm<AddContactInputs>({
    mode: 'all',
    defaultValues: {
      ...(recipientForEdit || {}),
      isTrusted: recipientForEdit?.isTrusted,
      primaryContactFirstName: recipientForEdit?.contactFirstName,
      primaryContactLastName: recipientForEdit?.contactLastName,
      primaryContactEmail: recipientForEdit?.contactEmail,
      isBuyer: recipientForEdit?.isCustomer,
      recipientCountry: recipientForEdit?.recipientCountry
        ? countryByCode(recipientForEdit.recipientCountry) || undefined
        : undefined,
      transferType: initialTransferType,
      currency: {
        name: initialCurrency.name,
        id: initialCurrency.id,
        icon: initialCurrency.countryCode,
        value: initialCurrency,
      },
      purpose: recipientForEdit?.purpose
        ? {
            name: recipientForEdit?.purpose?.description,
            id: recipientForEdit?.purpose?.description,
            value: recipientForEdit?.purpose,
          }
        : undefined,
      emails: recipientForEdit?.contactEmail
        ? recipientForEdit.contactEmail
            .split(',')
            .map(createCreatableSelectMenuOption)
        : [],
      doesUserAgreeToProceedWithWeakValidationMatch: false,
      userExplanationForSaveWithWeakMatch: '',
      howToProceed: undefined,
    },
  });

  const [isSwiftInputFocused, setIsSwiftInputFocused] = useState(false);
  const [routingType, setRoutingType] = useState(
    recipientForEdit?.routingType || null
  );
  const [routingType2, setRoutingType2] = useState(
    recipientForEdit?.routingType2 || null
  );
  const [isCreatingRecipient, setIsCreatingRecipient] = useState(false);
  const [isCreatingDraftRecipient, setIsCreatingDraftRecipient] = useState(
    false
  );
  const [countriesSearchValue, setCountriesSearchValue] = useState('');
  const [currenciesSearchValue, setCurrenciesSearchValue] = useState('');
  const [ibanBankData, setIbanBankData] = useState<any>(null);
  const [swiftBankData, setSwiftBankData] = useState<any>(null);
  const [routingNumberBankData, setRoutingNumberBankData] = useState<any>(null);
  const [accountCountry, setAccountCountry] = useState(
    recipientForEdit?.accountCountry
  );
  const [fieldsMetadata, setFieldsMetadata] = useState(initialMetadata);
  const [fieldGroups, setFieldGroups] = useState<IRecipientMetaField[]>([]);

  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);
  const [isPurposesLoading, setIsPurposesLoading] = useState(true);
  const [purposes, setPurposes] = useState<any>(null);

  const recipientEntityTypeValue = watch('recipientEntityType');
  const currencyValue = watch('currency');
  const recipientNameValue = watch('recipientName');
  const transferTypeValue = watch('transferType');
  const recipientCountryValue = watch('recipientCountry');
  const emailsValue = watch('emails');
  const purposeValue = watch('purpose');
  const bicSwiftValue = watch('bicSwift');
  const routingNumberValue = watch('routingNumber');
  const accountNumberValue = watch('accountNumber');
  const doesUserAgreeToProceedWithoutValidationMatchValue = watch(
    'doesUserAgreeToProceedWithWeakValidationMatch'
  );
  const previousAccountNumberValue = usePrevious(accountNumberValue);

  const {
    isAwaitingRegistration,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();

  const [openDirtyPopup, setOpenDirtyPopup] = useState(false);
  const [showScamAlert, setShowScamAlert] = useState(false);

  const [isLoadingCompanyDetails, setIsLoadingCompanyDetails] = useState(true);
  const [scamAlertAnswer, setScamAlertAnswer] = useState<
    TScamQuestions | undefined
  >(recipientForEdit?.scamAlert?.answer);

  const existingCurrency = currencyValue.id;

  const accountGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Account Details'),
    [fieldGroups]
  );

  const paymentGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Account details'),
    [fieldGroups]
  );

  const swiftGroupFields = useMemo(
    () =>
      fieldGroups?.filter(
        (item) =>
          item.title === 'Detailed address (required for SWIFT payments)'
      ),
    [fieldGroups]
  );

  const contactGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Contact details'),
    [fieldGroups]
  );

  const otherGroupFields = useMemo(
    () => fieldGroups?.find((item) => item.title === 'Additional information'),
    [fieldGroups]
  );

  const swiftOurs = accountCountry
    ? getSwiftOurs(sellCurrency.code, accountCountry)
    : 0;
  const swiftShared = getSwiftShared(sellCurrency.code);

  // set recipient entity type
  useEffect(() => {
    if (!recipientEntityTypeValue && fieldsMetadata) {
      const defaultRecipientType = fieldsMetadata?.company
        ? RECIPIENT_TYPE.company
        : RECIPIENT_TYPE.individual;

      setValue('recipientEntityType', defaultRecipientType);
    } else if (
      !recipientEntityTypeValue &&
      !fieldsMetadata?.company &&
      recipientEntityTypeValue === RECIPIENT_TYPE.company
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.individual);
    } else if (
      !recipientEntityTypeValue &&
      !fieldsMetadata?.individual &&
      recipientEntityTypeValue === RECIPIENT_TYPE.individual
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    } else if (
      recipientEntityTypeValue === RECIPIENT_TYPE.individual &&
      fieldsMetadata &&
      !fieldsMetadata?.individual
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    } else if (
      recipientEntityTypeValue === RECIPIENT_TYPE.company &&
      fieldsMetadata &&
      !fieldsMetadata?.company
    ) {
      setValue('recipientEntityType', RECIPIENT_TYPE.company);
    }
  }, [fieldsMetadata, recipientEntityTypeValue, setValue]);

  // update account country based on bank details
  useEffect(() => {
    const bankDetails = swiftBankData || ibanBankData || routingNumberBankData;
    const bankDetailsCountry = _get(
      bankDetails,
      'bankCountry_ISO',
      ''
    ).toLowerCase();

    if (bankDetails && bankDetailsCountry !== accountCountry) {
      setAccountCountry(bankDetailsCountry);
    }
  }, [swiftBankData, ibanBankData, routingNumberBankData, accountCountry]);

  // set SWIFT automatically when we have it inside routing bank details
  useEffect(() => {
    if (!bicSwiftValue && routingNumberBankData && !isSwiftInputFocused) {
      setValue('bicSwift', routingNumberBankData.bicSwift, {
        shouldValidate: true,
      });
    }
  }, [routingNumberBankData, bicSwiftValue, isSwiftInputFocused, setValue]);

  // set SWIFT automatically when we have it inside iban bank details
  useEffect(() => {
    if (!bicSwiftValue && ibanBankData && !isSwiftInputFocused) {
      setValue('bicSwift', ibanBankData.bicSwift, {
        shouldValidate: true,
      });
    }
  }, [ibanBankData, bicSwiftValue, isSwiftInputFocused, setValue]);

  // show different fields based on recipient entity type and transfer type
  useEffect(() => {
    if (fieldsMetadata && recipientEntityTypeValue) {
      const metadataForUse =
        fieldsMetadata[recipientEntityTypeValue]?.[transferTypeValue];

      if (metadataForUse) {
        setFieldGroups(_orderBy(Object.values(metadataForUse), 'order'));
      }
    }
  }, [recipientEntityTypeValue, transferTypeValue, fieldsMetadata]);

  useEffect(() => {
    setIsLoadingMetadata(true);

    getRecipientCreationMetadata({
      accountCountry: accountCountry,
      currency: currencyValue.value.code,
    })
      .then((data: any) => {
        if (!data) {
          return;
        }

        setFieldsMetadata(data);
      })
      .catch()
      .finally(() => {
        setIsLoadingMetadata(false);
      });
  }, [getRecipientCreationMetadata, currencyValue, accountCountry]);

  const canBeRegular = useMemo(
    () =>
      !!(
        _get(fieldsMetadata, 'company.regular', null) ||
        _get(fieldsMetadata, 'individual.regular', null)
      ),
    [fieldsMetadata]
  );

  const canBeSwift = useMemo(
    () =>
      !!(
        _get(fieldsMetadata, 'company.priority', null) ||
        _get(fieldsMetadata, 'individual.priority', null)
      ),
    [fieldsMetadata]
  );

  const purposesToUse = useMemo(
    () =>
      purposes?.[recipientEntityTypeValue ?? '']?.map((item: any) => ({
        id: item.description,
        value: item,
        name: item.description,
      })),
    [purposes, recipientEntityTypeValue]
  );

  useEffect(() => {
    getTransferPurposes({
      currency: currencyValue?.value?.code,
      accountCountry,
    })
      .then(({ data }: any) => {
        setPurposes(data);
      })
      .catch((error: any) => console.log(error))
      .finally(() => {
        setIsPurposesLoading(false);
      });
  }, [getTransferPurposes, setPurposes, accountCountry, currencyValue]);

  useEffect(() => {
    if (!canBeSwift && canBeRegular) {
      setValue('transferType', TRANSFER_TYPE.regular);
    } else if (!canBeRegular && canBeSwift) {
      setValue('transferType', TRANSFER_TYPE.priority);
    }
  }, [canBeRegular, canBeSwift, setValue]);

  useEffect(() => {
    if (!recipientForEdit && existingCurrency !== 'GBP') {
      if (existingCurrency === 'EUR') {
        setValue('transferType', TRANSFER_TYPE.regular);
      } else {
        setValue('transferType', TRANSFER_TYPE.priority);
      }
    }
  }, [existingCurrency, recipientForEdit, setValue]);

  const [entityCompanyDetails, setEntityCompanyDetails] = useState<
    Pick<IEntityDetails, 'countriesSendingMoneyTo'>
  >();

  useEffect(() => {
    const getEntityCompanyDetails = async () => {
      try {
        if (!entityId) {
          return;
        }

        if (!isEntityOnboarded) {
          setIsLoadingCompanyDetails(false);
          return;
        }

        setIsLoadingCompanyDetails(true);

        const response = await Firebase.getEntityCompanyDetails(entityId);

        if (response?.data?.success) {
          setEntityCompanyDetails(response.data.data);
        } else {
          Notify.error(response?.data?.message ?? '');
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingCompanyDetails(false);
      }
    };

    getEntityCompanyDetails();
  }, [entityId, isEntityOnboarded, setIsLoadingCompanyDetails]);

  const isCompanyType = recipientEntityTypeValue === RECIPIENT_TYPE.company;
  const orderedCountries = useMemo(() => _orderBy(countries, 'name', 'asc'), [
    countries,
  ]);
  const isRegular = useMemo(() => transferTypeValue === TRANSFER_TYPE.regular, [
    transferTypeValue,
  ]);
  const isRegularEUR = useMemo(
    () =>
      currencyValue.value.code === 'EUR' &&
      transferTypeValue === TRANSFER_TYPE.regular,
    [currencyValue, transferTypeValue]
  );

  const isSaveAsDraftButtonDisabled =
    isLoadingMetadata ||
    isLoadingCompanyDetails ||
    isSubmitting ||
    isCreatingDraftRecipient ||
    isCreatingRecipient;

  const isSaveFullContactButtonDisabled =
    isSaveAsDraftButtonDisabled || !isEntityOnboarded;

  const {
    groupedInvoiceAttachments,
    isLoadingInvoices,
    partialRecipients,
    isLoadingPartialRecipients,
  } = useImportDetails({
    recipientForEdit,
    existingCurrency,
  });

  const onSubmit = async ({
    values,
    isDraft = false,
    isScamAlertAnswered = false,
  }: {
    values: AddContactInputs;
    isDraft?: boolean;
    isScamAlertAnswered?: boolean;
  }) => {
    if (isRecipientValidationEnabled && !isDraft) {
      const validationResultInSubmit = await validateRecipientHandler(
        recipientForEdit?.id
      );

      if (!validationResultInSubmit) {
        return;
      }

      if (validationResultInSubmit.matchScoreDescription === 'Strong Match') {
        // special requirement to wait for 0.5 seconds before proceeding after strong match
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      if (validationResultInSubmit?.matchScoreDescription === 'Partial Match') {
        // user must choose how to proceed, so return if not chosen
        if (!values.howToProceed) {
          return;
        }

        values.doesUserAgreeToProceedWithWeakValidationMatch = true;

        if (values.howToProceed === 'updateAccountName') {
          if (!validationResultInSubmit.suggestedRecipientName) {
            Notify.error(
              'Recipient name not found in validation results. Please contact support.'
            );
            return;
          }

          values.recipientName =
            validationResultInSubmit.suggestedRecipientName;
        }
      }

      if (
        validationResultInSubmit?.matchScoreDescription === 'Weak Match' &&
        (!values.doesUserAgreeToProceedWithWeakValidationMatch ||
          !values.userExplanationForSaveWithWeakMatch)
      ) {
        Notify.info(
          'Please confirm you are happy to proceed with this recipient and provide an explanation.'
        );
        return;
      }
    }

    const {
      recipientName,
      routingNumber,
      routingNumber2,
      accountNumber,
      bicSwift,
      bankName,
      recipientCountry,
      currency,
      purpose,
      isBuyer,
      isSupplier,
      shouldSendRemittance,
      isTrusted,
      emails,
      ...restValues
    } = values;

    let accountDetails: Record<string, unknown>;

    if (!isCompanyType && recipientName.trim().indexOf(' ') === -1) {
      Notify.info('Please enter your both first name and last name.');
      return;
    }

    // Only do scam alert check if IpId recipient validation is not enabled
    if (!isRecipientValidationEnabled) {
      const isContactCountrySuspicious = isContactCountrySuspiciousCheck({
        entityKnownCountries: entityCompanyDetails?.countriesSendingMoneyTo,
        recipientCountry,
        recipientForEdit,
      });

      if (
        recipientForEdit &&
        isContact(recipientForEdit) &&
        isEntityOnboarded
      ) {
        const contactPaymentDetailsChanged =
          dirtyFields.bicSwift ||
          dirtyFields.accountNumber ||
          dirtyFields.routingNumber ||
          dirtyFields.routingNumber2;

        if (
          !isScamAlertAnswered &&
          (contactPaymentDetailsChanged || isContactCountrySuspicious)
        ) {
          setScamAlertAnswer(undefined);
          setShowScamAlert(true);
          return;
        }
      } else {
        if (
          !isScamAlertAnswered &&
          isContactCountrySuspicious &&
          !scamAlertAnswer
        ) {
          setShowScamAlert(true);
          return;
        }
      }
    }

    if (isRegularEUR) {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.iban],
        accountNumber,
        accountCountry,
      };
    } else if (isRegular) {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.local],
        routingType,
        routingNumber,
        routingType2,
        routingNumber2,
        accountNumber,
        bicSwift,
        accountCountry,
      };
    } else {
      accountDetails = {
        paymentType: PAYMENT_TYPE[PAYMENT_TYPE.swift],
        routingType,
        routingNumber,
        accountNumber,
        bicSwift,
        bankName,
        accountCountry,
      };
    }

    // USD and GBP usecases
    if (currency.value.code === 'USD' || currency.value.code === 'GBP') {
      accountDetails = {
        routingType,
        paymentType:
          transferTypeValue === TRANSFER_TYPE.priority
            ? PAYMENT_TYPE[PAYMENT_TYPE.swift]
            : PAYMENT_TYPE.local,
        routingNumber,
        accountNumber,
        bicSwift,
        bankName,
        accountCountry,
      };
    }

    if (!accountDetails) {
      Notify.error('Account details not correct.');
      return;
    }

    if (isDraft) {
      setIsCreatingDraftRecipient(true);
    } else {
      setIsCreatingRecipient(true);
    }

    const recipientData: IRecipient = {
      currency: currency.value.code,
      recipientEntityType: recipientEntityTypeValue,
      recipientCountry: recipientCountry?.alpha2,
      recipientName,
      isCustomer: !!isBuyer,
      isSupplier: !!isSupplier,
      shouldSendRemittance,
      purpose: purpose?.value,
      isTrusted,
      contactEmail: emails?.map((email) => email.value).join(','),
      // account details are read only, rework later when we have correct inputs and can omit react-hook-form
      ...(_omit(
        restValues,
        'accountDetails',
        'transferType',
        'howToProceed'
      ) as any),
      ...accountDetails,
      validationMatchScore: validationResult?.matchScore,
      validationMatchDescription: validationResult?.matchScoreDescription,
    };

    if (scamAlertAnswer) {
      recipientData.scamAlert = {
        answer: scamAlertAnswer,
        date: dayjs().format(DB_DATE_FORMAT),
      };
    }

    recipientData.externalRefs = {
      sourceSystem: recipientForEdit?.externalRefs?.sourceSystem,
      sourceSystemId: recipientForEdit?.externalRefs?.sourceSystemId,
    };

    if (isContact(recipientForEdit) && !isForceCreate) {
      // update recipient
      const response = await updateRecipient({
        recipientData,
        recipientId: recipientForEdit.id,
        isDraft,
      });

      if (isDraft) {
        setIsCreatingDraftRecipient(false);
      } else {
        setIsCreatingRecipient(false);
      }

      if (response && response.success) {
        const { id, data: newRecipient } = response;

        setRecipient?.({ ...newRecipient, id });
        onClose();
      }
    } else {
      const response = await createRecipient({
        recipient: recipientData,
        isDraft,
      });

      if (isDraft) {
        setIsCreatingDraftRecipient(false);
      } else {
        setIsCreatingRecipient(false);
      }

      if (response && response.success) {
        const { id, data: newRecipient } = response;
        if (!newRecipient.enabled) {
          onClose();
        } else {
          setRecipient?.({ ...newRecipient, id });

          // TODO: remove this hack later when we will take transfer type from recipient
          // setTransferTypeFromProps(transferType);
          if (onContinue) {
            onContinue();
          } else {
            onClose();
          }
        }
      }
    }
  };

  const onSaveAsDraft = async () => {
    clearErrors();

    const values = watch();
    let isValid = true;

    const valuesToValidate = Object.entries(values).reduce<string[]>(
      (total, [key, value]) => {
        if ((key === 'accountNumber' || key === 'bicSwift') && value) {
          total.push(key);
        }

        return total;
      },
      []
    );

    if (valuesToValidate.length) {
      isValid = await trigger(valuesToValidate);
    }

    if (isValid) {
      onSubmit({ values, isDraft: true });
    }
  };

  const addContactFormFieldData = {
    recipientForEdit,
    watch,
    control,
    setValue,
    errors,
    validateOnMount,
    swiftBankData,
    setSwiftBankData,
    setIsSwiftInputFocused,
    ibanBankData,
    setIbanBankData,
    routingNumberBankData,
    setRoutingNumberBankData,
    orderedCountries,
    countriesSearchValue,
    recipientCountry: recipientCountryValue,
    currenciesSearchValue,
    setCurrenciesSearchValue,
    disableCurrency,
    setCountriesSearchValue,
    setAccountCountry,
    swiftOurs,
    swiftShared,
    sellCurrency,
    isPurposesLoading,
    purposeValue,
    purposesToUse,
    emails: emailsValue,
    setRoutingType,
    setRoutingType2,
  };

  const [isImportFromInvoice, setIsImportFromInvoice] = useState(false);
  const [isImportFromBank, setIsImportFromBank] = useState(false);
  const [fieldsImportedFromOutside, setFieldsImportedFromOutside] = useState<
    string[]
  >([]);

  const hideRightSectionFields = isImportFromInvoice || isImportFromBank;

  const onApplyImportedValues = (
    values: IImportableValuesFromOcr | Partial<TPartialRecipientWithId>
  ) => {
    const valueKeys = Object.keys(values);

    if (
      valueKeys.includes('bicSwift') &&
      transferTypeValue !== TRANSFER_TYPE.priority
    ) {
      setValue('transferType', TRANSFER_TYPE.priority);
    }

    if (
      valueKeys.includes('routingNumber') &&
      transferTypeValue !== TRANSFER_TYPE.regular
    ) {
      setValue('transferType', TRANSFER_TYPE.regular);
    }

    // Timeout is required in order to allow form to re-render once and display new fields.
    // This is required when transfer type is changed, so we need to display new fields before applying values.

    // This can be done with useEffect instead of setTimeout, but the fundamental problem is that our form does not support
    // setting field values when fields are not rendered. Once we fix that, we can remove this timeout and won't need to
    // use any workarounds.
    setTimeout(() => {
      valueKeys.forEach((key) => {
        if (values[key]) {
          if (key === 'currency') {
            const currency = currencyByCode(values[key]);

            if (currency) {
              setValue(key, {
                name: currency.id ?? '',
                id: currency.id ?? '',
                icon: currency.countryCode,
                value: currency,
              });
              setFieldsImportedFromOutside((prev) => [...prev, key]);
            }
            return;
          }

          if (key === 'recipientCountry') {
            const country = countryByCode(values[key]);

            if (country) {
              setValue(key, country);
              setFieldsImportedFromOutside((prev) => [...prev, key]);
            }
            return;
          }

          setValue(key, values[key]);
          setFieldsImportedFromOutside((prev) => [...prev, key]);
        }
      });
    }, 100);
  };

  const onChangeCallback = (field: IRecipientFieldInstructions) => {
    setFieldsImportedFromOutside((prev) =>
      prev.filter((filledField) => filledField !== field.name)
    );
  };

  const onShowImportFromBank = () => {
    if (isImportFromInvoice) {
      setIsImportFromInvoice(false);
    }

    setIsImportFromBank((prev) => !prev);
  };

  const onShowImportFromInvoice = () => {
    if (isImportFromBank) {
      setIsImportFromBank(false);
    }

    setIsImportFromInvoice((prev) => !prev);
  };

  const {
    isRecipientValidationEnabled,
    validateRecipientHandler,
    isValidationLoading,
    validationResult,
    validationError,
  } = useIpIdValidation({
    currencyValue,
    transferTypeValue,
    accountNumberValue,
    routingNumberValue,
    recipientNameValue,
    existingRecipient: recipientForEdit,
  });

  return createPortal(
    <Popup
      HeaderContent={
        <Row flex={1}>
          <Title variant="h3">
            {recipientForEdit && !isForceCreate
              ? 'Edit contact'
              : 'Add new contact'}
          </Title>

          {(!recipientForEdit || recipientForEdit?.status === 'draft') && (
            <Row gap={theme.spacing.m} mr>
              <Subtitle mbValue={theme.spacing.m} variant="bold">
                Import
                <BetaTag />
              </Subtitle>

              <Row justifyContent="flex-end" gap={theme.spacing.m}>
                <StaleInfo
                  infoSize="auto"
                  mode="hover"
                  strategy="fixed"
                  portal
                  placement="top"
                  disabled={isLoadingInvoices}
                  trigger={
                    <Button
                      disabled={
                        groupedInvoiceAttachments.length === 0 ||
                        isLoadingInvoices
                      }
                      type="button"
                      variant="secondary"
                      onClick={onShowImportFromInvoice}
                    >
                      Scan invoice
                    </Button>
                  }
                >
                  <Paragraph color="white">
                    {groupedInvoiceAttachments.length === 0
                      ? 'No linked invoices available for data extraction'
                      : 'Extract recipient details from linked invoices'}
                  </Paragraph>
                </StaleInfo>

                <StaleInfo
                  infoSize="auto"
                  mode="hover"
                  strategy="fixed"
                  portal
                  disabled={isLoadingPartialRecipients}
                  placement="top"
                  trigger={
                    <Button
                      // disable for non GBP as it's not supported yet
                      disabled={
                        partialRecipients.length === 0 ||
                        isLoadingPartialRecipients ||
                        existingCurrency !== 'GBP'
                      }
                      type="button"
                      variant="secondary"
                      onClick={onShowImportFromBank}
                    >
                      Bank feed
                    </Button>
                  }
                >
                  <Paragraph color="white">
                    {partialRecipients.length === 0
                      ? 'No bank connected via open banking. Go to Settings => Bank accounts to enable Open Banking feeds'
                      : 'Download recipient details from your linked bank account'}
                  </Paragraph>
                </StaleInfo>
              </Row>
            </Row>
          )}
        </Row>
      }
      width="100%"
      maxWidth="1280px"
      height="840px"
      onClose={() => {
        if (
          !!Object.keys(dirtyFields).length &&
          !isAwaitingRegistration &&
          !isAwaitingComplianceReview &&
          isEntityOnboarded
        ) {
          setOpenDirtyPopup(true);
        } else {
          onClose();
        }
      }}
      FooterContent={
        <FooterContent
          recipientForEdit={recipientForEdit}
          isEntityOnboarded={isEntityOnboarded}
          isCreatingRecipient={isCreatingRecipient}
          isSubmitting={isSubmitting}
          withSaveAsDraft={withSaveAsDraft}
          isCreatingDraftRecipient={isCreatingDraftRecipient}
          isSaveFullContactButtonDisabled={isSaveFullContactButtonDisabled}
          isSaveAsDraftButtonDisabled={isSaveAsDraftButtonDisabled}
          onSaveAsDraft={onSaveAsDraft}
        />
      }
    >
      <AddContactFormWrapper>
        {isLoadingMetadata && (
          <LoaderWrapper>
            <Loader size="large" />
          </LoaderWrapper>
        )}

        <StyledForm
          id="add-contact-form"
          flex={1}
          onSubmit={(event) => {
            event.preventDefault();

            setIsImportFromInvoice(false);
            setIsImportFromBank(false);

            if (isAwaitingRegistration || isAwaitingComplianceReview) {
              setShowLimitedAccess(true);
              return;
            }

            if (!isCreatingRecipient) {
              handleSubmit((values) => onSubmit({ values }))();
            }
          }}
        >
          <Row
            flex={1}
            alignSelf="stretch"
            alignItems="flex-start"
            gap={theme.spacing.xl}
          >
            <Col flex={1} alignSelf="stretch" gap={theme.spacing.xl}>
              <FieldGroupWrapper rowGap={theme.spacing.m}>
                <Col>
                  <Row justifyContent="flex-start">
                    <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                      Account details
                    </Subtitle>
                    <TypesRow>
                      <Controller
                        name="recipientEntityType"
                        control={control}
                        defaultValue={null}
                        render={({ onChange, value }) => (
                          <>
                            <StaleInputRadioNew
                              id="business"
                              label="Business"
                              checked={value === RECIPIENT_TYPE.company}
                              onChange={() => onChange(RECIPIENT_TYPE.company)}
                              disabled={
                                // Disable Recipient Type radio buttons when contact is not new or in DRAFT state.
                                (!!recipientForEdit &&
                                  recipientForEdit.status !==
                                    RECIPIENT_STATUS.draft) ||
                                !fieldsMetadata?.company
                              }
                            />
                            <StaleInputRadioNew
                              id="individual"
                              label="Individual"
                              checked={value === RECIPIENT_TYPE.individual}
                              onChange={() =>
                                onChange(RECIPIENT_TYPE.individual)
                              }
                              disabled={
                                // Disable Recipient Type radio buttons when contact is not new or in DRAFT state.
                                (!!recipientForEdit &&
                                  recipientForEdit.status !==
                                    RECIPIENT_STATUS.draft) ||
                                !fieldsMetadata?.individual
                              }
                            />
                          </>
                        )}
                      />
                    </TypesRow>
                  </Row>
                  <Row>
                    <Row mb flex={1} flexWrap="nowrap" alignItems="flex-start">
                      {accountGroupFields &&
                        _orderBy(accountGroupFields.fields, 'order').map(
                          (field) => (
                            <FieldWrapper
                              isFillFromOutside={fieldsImportedFromOutside.includes(
                                field.name
                              )}
                              isCurrency={field.type === 'CURRENCY'}
                              accountGroup={true}
                              key={field.name}
                            >
                              <AddContactFormField
                                field={field}
                                {...addContactFormFieldData}
                              />
                            </FieldWrapper>
                          )
                        )}
                    </Row>
                  </Row>
                </Col>

                <Col>
                  <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                    Payment details
                  </Subtitle>

                  <Row justifyContent="flex-start">
                    <Paragraph mb>Payment method</Paragraph>
                    <TypesRow>
                      <Controller
                        name="transferType"
                        control={control}
                        defaultValue={null}
                        render={({ onChange, value }) => (
                          <>
                            <StaleInputRadioNew
                              id="transferType_local"
                              label="Local"
                              checked={value === TRANSFER_TYPE.regular}
                              onChange={() => onChange(TRANSFER_TYPE.regular)}
                              disabled={!canBeRegular}
                            />
                            <StaleInputRadioNew
                              id="transferType_swift"
                              label="SWIFT"
                              checked={value === TRANSFER_TYPE.priority}
                              onChange={() => onChange(TRANSFER_TYPE.priority)}
                              disabled={!canBeSwift}
                            />
                          </>
                        )}
                      />
                    </TypesRow>

                    <Row mb ml>
                      <StaleInfo
                        infoSize={theme.iconSizes.m}
                        mode="hover"
                        strategy="fixed"
                        placement="top"
                        portal
                      >
                        <Paragraph color="white" variant="bold">
                          Local:
                        </Paragraph>

                        <Row justifyContent="flex-start">
                          <Icon
                            width={theme.iconSizes.s}
                            icon="checkbox-checkmark"
                          />
                          <Paragraph color="white">
                            Lower cost or free within your monthly allowance
                          </Paragraph>
                        </Row>

                        <Row justifyContent="flex-start">
                          <Icon
                            width={theme.iconSizes.s}
                            icon="checkbox-checkmark"
                          />
                          <Paragraph color="white">
                            Paid in real-time or on the same day.
                          </Paragraph>
                        </Row>

                        <Row justifyContent="flex-start">
                          <Icon width={theme.iconSizes.s} icon="cross-ico" />
                          <Paragraph color="white">
                            Can't be traced or recalled.
                          </Paragraph>
                        </Row>

                        <Paragraph mt color="white" variant="bold">
                          SWIFT:
                        </Paragraph>
                        <Row justifyContent="flex-start">
                          <Icon
                            width={theme.iconSizes.s}
                            icon="checkbox-checkmark"
                          />
                          <Paragraph color="white">
                            Usually completed the same or next day.
                          </Paragraph>
                        </Row>

                        <Row justifyContent="flex-start">
                          <Icon
                            width={theme.iconSizes.s}
                            icon="checkbox-checkmark"
                          />
                          <Paragraph color="white">
                            Get MT103 as internationally accepted proof of
                            payment.
                          </Paragraph>
                        </Row>

                        <Row justifyContent="flex-start">
                          <Icon width={theme.iconSizes.s} icon="cross-ico" />
                          <Paragraph color="white">
                            Intermediary fees deducted from the transfer amount
                            for SHARED.
                          </Paragraph>
                        </Row>
                      </StaleInfo>
                    </Row>
                  </Row>
                  <Row
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap={theme.spacing.m}
                  >
                    {paymentGroupFields &&
                      _orderBy(paymentGroupFields.fields, 'order').map(
                        (field) => {
                          return (
                            <FieldRow
                              key={field.name}
                              fullWidth={
                                currencyValue?.id === 'EUR' &&
                                field.type === 'IBAN'
                              }
                            >
                              <FieldWrapper
                                isFillFromOutside={fieldsImportedFromOutside.includes(
                                  field.name
                                )}
                              >
                                <AddContactFormField
                                  field={field}
                                  onChangeCallback={onChangeCallback}
                                  {...addContactFormFieldData}
                                  defaultValue={
                                    field.type === 'IBAN'
                                      ? previousAccountNumberValue
                                      : undefined
                                  }
                                  validateOnMount={field.type === 'IBAN'}
                                  skipValidation={
                                    field.name === 'accountNumber' &&
                                    !bicSwiftValue &&
                                    !!paymentGroupFields.fields.find(
                                      (f) => f.type === 'BICSWIFT'
                                    )
                                  }
                                />
                              </FieldWrapper>
                            </FieldRow>
                          );
                        }
                      )}
                  </Row>
                </Col>

                <Col>
                  <Subtitle mb mbValue={theme.spacing.m} variant="bold">
                    Detailed address
                  </Subtitle>
                  <Row
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap={theme.spacing.m}
                  >
                    {swiftGroupFields?.[0] &&
                      _orderBy(swiftGroupFields[0]?.fields, 'order').map(
                        (field) => (
                          <FieldRow
                            key={field.name}
                            fullWidth={field.name === 'recipientAddress'}
                          >
                            <FieldWrapper
                              isFillFromOutside={fieldsImportedFromOutside.includes(
                                field.name
                              )}
                            >
                              <AddContactFormField
                                showOptionalText
                                field={field}
                                {...addContactFormFieldData}
                              />
                            </FieldWrapper>
                          </FieldRow>
                        )
                      )}
                  </Row>
                </Col>

                {recipientForEdit?.isTrusted && (
                  <div style={{ marginTop: 14 }}>
                    <StaleCheckboxControlled
                      id="isTrusted"
                      control={control}
                      name="isTrusted"
                      Label={
                        <Paragraph variant="bold">Trusted Payee</Paragraph>
                      }
                    />
                  </div>
                )}
              </FieldGroupWrapper>
            </Col>

            <Col
              flex={1}
              alignSelf="stretch"
              gap={
                !isImportFromInvoice && !isImportFromBank ? theme.spacing.xl : 0
              }
            >
              {isImportFromInvoice && (
                <InvoicesWithAttachments
                  onApplyImportedValues={onApplyImportedValues}
                  disableCurrency={
                    disableCurrency ||
                    (!!recipientForEdit &&
                      recipientForEdit.status !== RECIPIENT_STATUS.draft)
                  }
                  disableRecipientName={
                    !!recipientForEdit &&
                    recipientForEdit.status !== RECIPIENT_STATUS.draft
                  }
                  existingCurrency={existingCurrency}
                  existingRecipientName={recipientNameValue}
                  isLoadingInvoices={isLoadingInvoices}
                  groupedInvoiceAttachments={groupedInvoiceAttachments}
                />
              )}

              {isImportFromBank && (
                <ImportFromBank
                  onApplyImportedValues={onApplyImportedValues}
                  existingRecipientName={recipientNameValue}
                  existingCurrency={existingCurrency}
                  disableRecipientName={
                    !!recipientForEdit &&
                    recipientForEdit.status !== RECIPIENT_STATUS.draft
                  }
                  disableCurrency={disableCurrency}
                  isLoadingPartialRecipients={isLoadingPartialRecipients}
                  partialRecipients={partialRecipients}
                />
              )}

              <Col
                rowGap={theme.spacing.xl}
                style={
                  hideRightSectionFields
                    ? {
                        maxHeight: 0,
                        overflow: 'hidden',
                      }
                    : undefined
                }
              >
                {accountGroupFields && (
                  <Col rowGap={theme.spacing.m}>
                    <Subtitle variant="bold">
                      Contact details for remittances
                    </Subtitle>

                    <Row
                      flexWrap="wrap"
                      alignItems="flex-start"
                      gap={theme.spacing.m}
                    >
                      {contactGroupFields &&
                        _orderBy(contactGroupFields?.fields, 'order').map(
                          (field) => (
                            <FieldRow
                              key={field.name}
                              fullWidth={field.name === 'defaultReference'}
                            >
                              <FieldWrapper
                                isFillFromOutside={fieldsImportedFromOutside.includes(
                                  field.name
                                )}
                              >
                                <AddContactFormField
                                  field={field}
                                  {...addContactFormFieldData}
                                />
                              </FieldWrapper>
                            </FieldRow>
                          )
                        )}
                    </Row>
                  </Col>
                )}

                <Col rowGap={theme.spacing.m}>
                  <Subtitle variant="bold">Additional information</Subtitle>

                  <Row
                    flexWrap="wrap"
                    alignItems="flex-start"
                    gap={theme.spacing.m}
                  >
                    {otherGroupFields &&
                      _orderBy(otherGroupFields.fields, 'order').map(
                        (field) => {
                          return (
                            <FieldRow key={field.name}>
                              <FieldWrapper
                                isFillFromOutside={fieldsImportedFromOutside.includes(
                                  field.name
                                )}
                              >
                                <AddContactFormField
                                  field={field}
                                  {...addContactFormFieldData}
                                />
                              </FieldWrapper>
                            </FieldRow>
                          );
                        }
                      )}
                  </Row>
                </Col>
              </Col>

              <ValidationSection
                control={control}
                validationResult={validationResult}
                validationError={validationError}
                isValidationLoading={isValidationLoading}
                shouldAllowUpdatingName={
                  !recipientForEdit ||
                  recipientForEdit.status === RECIPIENT_STATUS.draft
                }
                doesUserAgreeToProceedWithoutValidationMatch={
                  !!doesUserAgreeToProceedWithoutValidationMatchValue
                }
                errors={errors}
              />
            </Col>
          </Row>

          {recipientForEdit &&
            recipientForEdit.status === RECIPIENT_STATUS.draft &&
            withDraftWarning && (
              <Col mt>
                <Paragraph>
                  Please note that this contact cannot be used during payments
                  until all validation errors have been fixed.{' '}
                </Paragraph>
                <Paragraph color="red">Status: Draft</Paragraph>
              </Col>
            )}
        </StyledForm>

        {showScamAlert && (
          <ScamAlert
            isLoading={isCreatingRecipient || isSubmitting}
            isConfirmDisabled={isSaveFullContactButtonDisabled}
            onConfirm={() =>
              handleSubmit((values) =>
                onSubmit({ values, isScamAlertAnswered: true })
              )()
            }
            onClose={() => setShowScamAlert(false)}
            setScamAlertAnswer={setScamAlertAnswer}
            scamAlertAnswer={scamAlertAnswer}
          />
        )}

        {openDirtyPopup && (
          <DirtyPopup
            onSubmit={() => {
              setOpenDirtyPopup(false);
              if (!isCreatingRecipient) {
                handleSubmit((values) => onSubmit({ values }))();
              }
            }}
            onClose={onClose}
            disabled={isCreatingRecipient && !isEntityOnboarded}
          />
        )}
      </AddContactFormWrapper>
    </Popup>,
    document.body
  );
};

export default AddContactForm;
